var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"ll-topbox"},[_c('div',{staticClass:"breadcrumb-top"},[_c('b-breadcrumb',{},[_c('b-breadcrumb-item',{attrs:{"to":{ name: 'dashboard' }}},[_c('feather-icon',{attrs:{"icon":"HomeIcon","size":"16"}})],1),_c('b-breadcrumb-item',[_vm._v(" "+_vm._s(_vm.$t('Delivery Order'))+" ")]),_c('b-breadcrumb-item',{attrs:{"to":{ name: 'orderList2' }}},[_vm._v(" "+_vm._s(_vm.$t('Order List'))+" ")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v(" "+_vm._s(_vm.$t('Create an order'))+" ")])],1)],1),_c('b-button',{staticClass:"ll-cancel",attrs:{"variant":"flat-secondary","to":{ name: 'intermediate-tip' }}},[_c('b-img',{staticClass:"mr-1",attrs:{"src":require('@/assets/images/imgs/return.png'),"height":"auto"}})],1)],1),_c('validation-observer',{ref:"rules"},[_c('b-card',{staticClass:"ll-card"},[_c('b-card-header',[_c('b-card-title',[_vm._v(_vm._s(_vm.$t('Pickup Location Information')))]),_c('div',{staticClass:"ll-required"},[_vm._v("* "+_vm._s(_vm.$t('Required Fields')))])],1),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Contact person","rules":{required:true,max:20,regex:/^((?!\\).)*$/s}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"ll-boldText"},[_vm._v(_vm._s(_vm.$t('Pickup Location Shipper Name')))]),_vm._v(" "+_vm._s(_vm.$t("(No \"\\\", Maximum Length: 20)"))+" "),_c('b-form-input',{model:{value:(_vm.info.contact_name),callback:function ($$v) {_vm.$set(_vm.info, "contact_name", $$v)},expression:"info.contact_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Contact phone","rules":{required:true,min:8,max:20,regex:/^((?!\\).)*$/s}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"ll-boldText"},[_vm._v(_vm._s(_vm.$t('Pickup Location Shipper Contact')))]),_vm._v(" "+_vm._s(_vm.$t("(No \"\\\", Maximum Length: 20)"))+" "),_c('b-form-input',{attrs:{"type":"number"},on:{"keydown":function($event){return _vm.formatNumber($event)}},model:{value:(_vm.info.contact_no),callback:function ($$v) {_vm.$set(_vm.info, "contact_no", $$v)},expression:"info.contact_no"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Company Name","rules":{max:50,regex:/^((?!\\).)*$/s}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{},[_vm._v(_vm._s(_vm.$t('Pickup Location Company Name')))]),_vm._v(" "+_vm._s(_vm.$t("(No \"\\\", Maximum Length: 50)"))+" "),_c('b-form-input',{model:{value:(_vm.info.company_name),callback:function ($$v) {_vm.$set(_vm.info, "company_name", $$v)},expression:"info.company_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t(''),"label-for":"address"}},[_c('validation-provider',{attrs:{"name":"Address","rules":{required:true,max:225,regex:/^((?!\\).)*$/s}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"ll-boldText"},[_vm._v(_vm._s(_vm.$t('Pickup Location Address')))]),_vm._v(" "+_vm._s(_vm.$t("(No \"\\\", Maximum Length: 225)"))+" "),_c('b-form-input',{model:{value:(_vm.info.address),callback:function ($$v) {_vm.$set(_vm.info, "address", $$v)},expression:"info.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t(''),"label-for":"district"}},[_c('validation-provider',{attrs:{"name":"District","rules":{max:50,regex:/^((?!\\).)*$/s}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{},[_vm._v(_vm._s(_vm.$t('Pickup Location District')))]),_vm._v(" "+_vm._s(_vm.$t("(No \"\\\", Maximum Length: 50)"))+" "),_c('b-form-input',{model:{value:(_vm.info.district),callback:function ($$v) {_vm.$set(_vm.info, "district", $$v)},expression:"info.district"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t(''),"label-for":"area"}},[_c('validation-provider',{attrs:{"name":"Pickup Location City","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"ll-boldText"},[_vm._v(_vm._s(_vm.$t('Pickup Location City')))]),_c('v-select',{attrs:{"options":_vm.areaList,"label":"codeDescription"},on:{"input":_vm.selArea},model:{value:(_vm.hkArea),callback:function ($$v) {_vm.hkArea=$$v},expression:"hkArea"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Schedule","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"ll-boldText"},[_vm._v(_vm._s(_vm.$t('Pickup Schedule')))]),_c('div',{staticStyle:{"display":"flex"}},[_c('flat-pickr',{staticClass:"form-control",staticStyle:{"width":"50%"},attrs:{"id":"formTime","config":_vm.dateConfig},model:{value:(_vm.pickup_schedule.date),callback:function ($$v) {_vm.$set(_vm.pickup_schedule, "date", $$v)},expression:"pickup_schedule.date"}}),_c('v-select',{staticStyle:{"width":"50%","margin-left":"5px"},attrs:{"options":_vm.timeList},on:{"input":_vm.selTime},model:{value:(_vm.pickup_schedule.time),callback:function ($$v) {_vm.$set(_vm.pickup_schedule, "time", $$v)},expression:"pickup_schedule.time"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Number Of Package","rules":{integer:true, max_value:99}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{},[_vm._v(_vm._s(_vm.$t('Number Of Package')))]),_vm._v(" "+_vm._s(_vm.$t("(Maximum: 99, Only accept Number)"))+" "),_c('b-form-input',{attrs:{"type":"number","min":"1","max":"99"},on:{"blur":function($event){_vm.info.no_of_packages=_vm.info.no_of_packages.replace(/^0(0+|\d+)|[^\d]+/g,'')},"keydown":function($event){return _vm.formatNumber($event)}},model:{value:(_vm.info.no_of_packages),callback:function ($$v) {_vm.$set(_vm.info, "no_of_packages", $$v)},expression:"info.no_of_packages"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Declared Gross Weight","rules":{max:10,regex:/^((?!\\).)*$/s}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{},[_vm._v(_vm._s(_vm.$t('Declared Gross Weight')))]),_vm._v(" "+_vm._s(_vm.$t("(Only accept Number, 2 decimal places, Maximum Length: 10)"))+" "),_c('b-form-input',{attrs:{"type":"number","placeholder":"KG"},on:{"keydown":function($event){return _vm.formatNumber($event)}},model:{value:(_vm.info.total_weight),callback:function ($$v) {_vm.$set(_vm.info, "total_weight", $$v)},expression:"info.total_weight"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Remark","rules":{regex:/^((?!\\).)*$/s}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{},[_vm._v(_vm._s(_vm.$t('Remark')))]),_vm._v(" "+_vm._s(_vm.$t("(No \"\\\")"))+" "),_c('b-form-input',{model:{value:(_vm.info.remark),callback:function ($$v) {_vm.$set(_vm.info, "remark", $$v)},expression:"info.remark"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('b-card',{staticClass:"ll-card"},[_c('b-card-header',[_c('b-card-title',[_vm._v(_vm._s(_vm.$t('Return Location Information')))])],1),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Consignee Name","rules":{required:true,max:20,regex:/^((?!\\).)*$/s}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"ll-boldText"},[_vm._v(_vm._s(_vm.$t('Consignee Name')))]),_vm._v(" "+_vm._s(_vm.$t("(No \"\\\", Maximum Length: 20)"))+" "),_c('b-form-input',{model:{value:(_vm.return_information.consignee_name),callback:function ($$v) {_vm.$set(_vm.return_information, "consignee_name", $$v)},expression:"return_information.consignee_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Phone","rules":{required:true,min:8,max:20,regex:/^((?!\\).)*$/s}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"ll-boldText"},[_vm._v(_vm._s(_vm.$t('Consignee Contact')))]),_vm._v(" "+_vm._s(_vm.$t("(No \"\\\", Maximum Length: 20)"))+" "),_c('b-form-input',{attrs:{"type":"number"},on:{"keydown":function($event){return _vm.formatNumber($event)}},model:{value:(_vm.return_information.consignee_phone),callback:function ($$v) {_vm.$set(_vm.return_information, "consignee_phone", $$v)},expression:"return_information.consignee_phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Remark","rules":{regex:/^((?!\\).)*$/s}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{},[_vm._v(_vm._s(_vm.$t('Remark')))]),_vm._v(" "+_vm._s(_vm.$t("(No \"\\\")"))+" "),_c('b-form-input',{model:{value:(_vm.package.remark),callback:function ($$v) {_vm.$set(_vm.package, "remark", $$v)},expression:"package.remark"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Consignee Address'),"label-for":"address"}},[_c('b-form-input',{attrs:{"disabled":true},model:{value:(_vm.return_information.Address),callback:function ($$v) {_vm.$set(_vm.return_information, "Address", $$v)},expression:"return_information.Address"}})],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Consignee District'),"label-for":"district"}},[_c('b-form-input',{attrs:{"disabled":true},model:{value:(_vm.return_information.District),callback:function ($$v) {_vm.$set(_vm.return_information, "District", $$v)},expression:"return_information.District"}})],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Consignee City'),"label-for":"area"}},[_c('v-select',{attrs:{"options":_vm.areaList,"label":"codeDescription","disabled":true},on:{"input":_vm.selConsigneeArea},model:{value:(_vm.hkConsigneeArea),callback:function ($$v) {_vm.hkConsigneeArea=$$v},expression:"hkConsigneeArea"}})],1)],1)],1)],1)],1),_c('div',{staticStyle:{"display":"flex","justify-content":"flex-end"}},[_c('b-button',{attrs:{"variant":"info"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" "+_vm._s(_vm.$t('Submit'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }