<template>
  <section>
    <div class="ll-topbox">
      <!-- breadcrumb -->
      <div class="breadcrumb-top">
        <b-breadcrumb class="">
          <b-breadcrumb-item :to="{ name: 'dashboard' }">
            <feather-icon
                icon="HomeIcon"
                size="16"
            />
          </b-breadcrumb-item>
          <b-breadcrumb-item>
            {{ $t('Delivery Order') }}
          </b-breadcrumb-item>
          <b-breadcrumb-item :to="{ name: 'orderList2' }">
            {{ $t('Order List') }}
          </b-breadcrumb-item>
          <b-breadcrumb-item active>
            {{ $t('Create an order') }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
      <!-- back button -->
      <b-button
          variant="flat-secondary"
          class="ll-cancel"
          :to="{ name: 'intermediate-tip' }"
      >
        <b-img
            :src="require('@/assets/images/imgs/return.png')"
            height="auto"
            class="mr-1"
        />
      </b-button>
    </div>

    <validation-observer ref="rules">
      <b-card class="ll-card">
        <b-card-header>
          <b-card-title>{{ $t('Pickup Location Information') }}</b-card-title>
          <div class="ll-required">* {{ $t('Required Fields') }}</div>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col lg="4">
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    name="Contact person"
                    :rules="{required:true,max:20,regex:/^((?!\\).)*$/s}"
                >
                  <label class="ll-boldText">{{ $t('Pickup Location Shipper Name') }}</label> {{ $t("(No \"\\\", Maximum Length: 20)") }}
                  <b-form-input v-model="info.contact_name"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    name="Contact phone"
                    :rules="{required:true,min:8,max:20,regex:/^((?!\\).)*$/s}"
                >
                  <label class="ll-boldText">{{ $t('Pickup Location Shipper Contact') }}</label> {{ $t("(No \"\\\", Maximum Length: 20)") }}
                  <b-form-input v-model="info.contact_no" type="number" @keydown="formatNumber($event)"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    name="Company Name"
                    :rules="{max:50,regex:/^((?!\\).)*$/s}"
                >
                  <label class="">{{ $t('Pickup Location Company Name') }}</label> {{ $t("(No \"\\\", Maximum Length: 50)") }}
                  <b-form-input v-model="info.company_name"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="4">
              <b-form-group
                  :label="$t('')"
                  label-for="address"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Address"
                    :rules="{required:true,max:225,regex:/^((?!\\).)*$/s}"
                >
                  <label class="ll-boldText">{{ $t('Pickup Location Address') }}</label> {{ $t("(No \"\\\", Maximum Length: 225)") }}
                  <b-form-input v-model="info.address"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group
                  :label="$t('')"
                  label-for="district"
              >
                <validation-provider
                    #default="{ errors }"
                    name="District"
                    :rules="{max:50,regex:/^((?!\\).)*$/s}"
                >
                  <label class="">{{ $t('Pickup Location District') }}</label> {{ $t("(No \"\\\", Maximum Length: 50)") }}
                  <b-form-input v-model="info.district"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group
                  :label="$t('')"
                  label-for="area"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Pickup Location City"
                    rules="required"
                >
                  <label class="ll-boldText">{{ $t('Pickup Location City') }}</label>
                  <v-select
                      :options="areaList"
                      label="codeDescription"
                      @input="selArea"
                      v-model="hkArea"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6">
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    name="Schedule"
                    rules="required"
                >
                  <label class="ll-boldText">{{ $t('Pickup Schedule') }}</label>
                  <div style="display:flex">
                    <flat-pickr
                        id="formTime"
                        v-model="pickup_schedule.date"
                        class="form-control"
                        :config="dateConfig" style="width: 50%;"
                    />
                    <v-select
                        :options="timeList"
                        @input="selTime"
                        v-model="pickup_schedule.time"
                        style="width: 50%;margin-left:5px"
                    />
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

          </b-row>
          <b-row>
            <b-col lg="4">
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    name="Number Of Package"
                    :rules="{integer:true, max_value:99}"
                >
                  <label class="">{{ $t('Number Of Package') }}</label> {{ $t("(Maximum: 99, Only accept Number)") }}
                  <b-form-input v-model="info.no_of_packages" type="number" min="1" max="99"
                                @blur="info.no_of_packages=info.no_of_packages.replace(/^0(0+|\d+)|[^\d]+/g,'')"
                                @keydown="formatNumber($event)"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    name="Declared Gross Weight"
                    :rules="{max:10,regex:/^((?!\\).)*$/s}"
                >
                  <label class="">{{ $t('Declared Gross Weight') }}</label>
                  {{ $t("(Only accept Number, 2 decimal places, Maximum Length: 10)") }}
                  <b-form-input v-model="info.total_weight" type="number" @keydown="formatNumber($event)" placeholder="KG"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    name="Remark"
                    :rules="{regex:/^((?!\\).)*$/s}"
                >
                  <label class="">{{ $t('Remark') }}</label>
                  {{ $t("(No \"\\\")") }}
                  <b-form-input v-model="info.remark"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <b-card class="ll-card">
        <b-card-header>
          <b-card-title>{{ $t('Return Location Information') }}</b-card-title>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col lg="4">
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    name="Consignee Name"
                    :rules="{required:true,max:20,regex:/^((?!\\).)*$/s}"
                >
                  <label class="ll-boldText">{{ $t('Consignee Name') }}</label> {{ $t("(No \"\\\", Maximum Length: 20)") }}
                  <b-form-input v-model="return_information.consignee_name"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    name="Phone"
                    :rules="{required:true,min:8,max:20,regex:/^((?!\\).)*$/s}"
                >
                  <label class="ll-boldText">{{ $t('Consignee Contact') }}</label> {{ $t("(No \"\\\", Maximum Length: 20)") }}
                  <b-form-input v-model="return_information.consignee_phone" type="number" @keydown="formatNumber($event)"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    name="Remark"
                    :rules="{regex:/^((?!\\).)*$/s}"
                >
                  <label class="">{{ $t('Remark') }}</label>
                  {{ $t("(No \"\\\")") }}
                  <b-form-input v-model="package.remark"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="4">
              <b-form-group
                  :label="$t('Consignee Address')"
                  label-for="address"
              >
                <b-form-input v-model="return_information.Address" :disabled="true"/>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group
                  :label="$t('Consignee District')"
                  label-for="district"
              >
                <b-form-input v-model="return_information.District" :disabled="true"/>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group
                  :label="$t('Consignee City')"
                  label-for="area"
              >
                <v-select
                    :options="areaList"
                    label="codeDescription"
                    @input="selConsigneeArea"
                    v-model="hkConsigneeArea"
                    :disabled="true"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <!-- submit btn -->
      <div style="display:flex;justify-content:flex-end">
        <b-button
            variant="info"
            @click.prevent="validationForm"
        >
          {{ $t('Submit') }}
        </b-button>
      </div>
    </validation-observer>
  </section>
</template>
<script>
import {
  BRow, BCol, BCard, BFormGroup, BFormInput,
  BButton, BInputGroupPrepend, BInputGroup,
  BCardHeader, BCardTitle, BCardBody, BTab, BTabs,
  BFormSelect,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import vSelect from 'vue-select'
import {required} from '@validations'
import {formatNumber} from "@core/utils/filter";
import { debounce } from "@/libs/fun.js"

export default {
  components: {
    vSelect, flatPickr,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    BInputGroupPrepend,
    BInputGroup,
    BTab,
    BTabs,
    BFormSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      hkArea: '',
      hkConsigneeArea: '',
      info: {},
      timeList: ['AM', 'PM'],
      areaList: [],
      package: {
        remark: null,
      },
      pickup_schedule: {
        date: null,
        time: 'AM'
      },
      return_information: {
        Address: '',
        District: '',
        Area: ''
      },
      dateConfig: {
        enableTime: false, dateFormat: 'Y-m-d', "disable": [
          function (date) {
            return (date.getDay() === 0 || new Date((new Date).getTime() - 24 * 60 * 60 * 1000) > date)
          }
        ],
      }
    }
  },
  mounted() {
    this.queryArea()
    const userinfo = JSON.parse(localStorage.getItem('userInfo'))
    this.selCustomer(userinfo)
  },
  methods: {
    formatNumber,
    validationForm: debounce(function() {
      this.$refs.rules.validate().then(success => {
        if (success) {
          this.createReturnOrder()
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please check the data',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          return false
        }
        return true
      })
    }, 500),
    createReturnOrder() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Submitting, please wait...",
          icon: 'InfoIcon',
          variant: 'info',
        },
      })
      this.info.pickup_schedule = this.pickup_schedule
      let params = {
        'package': this.package,
        'pickup_information': this.info,
        'return_information': this.return_information
      }
      this.$http.post('/dest/create', params).then(res => {
        if (res.data.code === '200') {
          this.$router.push({name: 'orderList2'})
        }
      })
    },
    selArea(d) {
      this.info.area = d ? d.codeName : null
      this.hkArea = d ? d.codeDescription : null
    },
    selConsigneeArea() {
      this.return_information.Area = d ? d.codeName : null
      this.hkConsigneeArea = d ? d.codeDescription : null
    },
    queryArea() {
      this.$http.get('/masterData/findAllByDataType', {params: {data_type: 'hkArea'}}).then(res => {
        this.areaList = res.data.data
      })
    },
    selCustomer(d) {
      this.$http.get('/lmd/pickAddress/find?orgCode=' + d.orgCode).then(res => {
        if (res.data.data) {
          this.return_information.Address = res.data.data.pickUpAddress
          this.hkConsigneeArea = res.data.data.pickUpArea
          this.return_information.District = res.data.data.pickUpDistrict
          this.return_information.Area = ''
        } else {
          this.return_information.Address = ''
          this.hkConsigneeArea = ''
          this.return_information.District = ''
          this.return_information.Area = ''
        }

      })
    },
    selTime(d) {
      this.info.time = d ? d : null
    },
    //     onOpen(selectedDates, dateStr, instance){
    //         console.info(selectedDates, dateStr, instance,'--')
    //         let time = (new Date).getTime() - 24 * 60 * 60 * 1000;
    //         instance.set('disable', [
    //             {
    //                 from: "1970-01-01",
    //                 to: new Date(time)// 获取的是前一天日期
    //             },
    //         ])
    //    },
  }
}
</script>
<style>
[dir] .vs--disabled .vs__dropdown-toggle, [dir] .vs--disabled .vs__clear,
[dir] .vs--disabled .vs__search, [dir] .vs--disabled .vs__selected,
[dir] .vs--disabled .vs__open-indicator {
  background-color: #EFEFEF !important;
}
</style>